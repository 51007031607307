import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { grey, black } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    background: {
      default: grey[200]
    },
    primary: {
      light: "#DA7272",
      main: "#C11314",
      contrastText: '#fff',
    },
    secondary: {
      light: grey[500],
      main: grey[700],
      dark: grey[900],
      contrastText: '#fff',
    },
  },
  typography: {
    small: {
      fontSize: '0.7rem'
    }
  },
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 13,
        }
      }
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginTop: 5,
          "& .MuiTypography-root": {
            fontSize: '0.8rem',
            lineHeight: '1rem',
          },
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 5,
          fontSize: '1rem'
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          "& input": {
            paddingBottom: 15,
            paddingTop: 15,
            height: '1rem',
            lineHeight: '1rem',
          },
          "& label": {
            lineHeight: '1rem'
          },
          "& .MuiInputBase-root": {
            borderRadius: 5,
          }
        }
      }
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
          textDecoration: 'none'
        }
      }
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          '&:active': {
            outline: 'none',
          },
          '&:target': {
            outline: 'none',
          },
          '&:focus': {
            outline: 'none',
          },
          "& .MuiPaper-root": {
            background: '#fff',
            maxWidth: 400,
            // maxWidth: '90vw',
            overflowY: 'scroll',
            maxHeight: '90vh',
          },
        }
      }
    }
  },
});
theme.typography.h3 = {
  fontSize: '2rem',
  color: theme.palette.primary.main
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ThemeProvider theme={theme}>
    <Router>
      <Routes>
        <Route path='/' element={<App />}>
          <Route path='resource/:external_id' element={<App />} />
        </Route>
      </Routes>
    </Router>
  </ThemeProvider>

);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
